import MenuBar from "../MenuBar/MenuBar";
import {Client} from "@notionhq/client";
import React, {useEffect} from "react";


export const notion = new Client({
    auth: 'secret_2Xnhvr6qzzfZAwe8Xl7S2xDqDHAfX6xlhS6oCheu3AC'
    //process.env.REACT_APP_NOTION_KEY,
});


export default function BlogPage() {


    useEffect(() => {
            const fetchData = async () => {
                try {
                    const response = await notion.databases.retrieve({
                        database_id: '6db0ceb8e37544a7aa6988eda77139d9',
                        //process.env.REACT_APP_NOTION_DATABASE_ID,
                    });
                    console.log(response);
                } catch (error) {
                    console.error('Error fetching data: ', error);
                }
            }
            fetchData()
        })


    return (
        <MenuBar/>
    )
}